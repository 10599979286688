<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: allStocks.vue
Description:This file is no longer in use.the component feed inside these file in mixed with feeding.vue and labtest.vue
-->
<template>
  <layout-pondlogs headerType="custom" class="resources-view">
    <template slot="layout-pondlogs-scrollable-main">
      <er-card>
        <Loader v-show="loading" />
        <er-top-route-nav
          :items="filterTabComponents"
          type="tabs"
        ></er-top-route-nav>
        <el-row>
          <component
            :tabData="components[computedComponentName]"
            :is="computedComponentName"
            @change-tab="handleChangeTabTo"
          />
        </el-row>
      </er-card>
    </template>
  </layout-pondlogs>
</template>

<script>
import feed from "@/views/stock/feed";
import medicine from "@/views/stock/medicine";
import redirectsMixin from "@/mixins/redirectsMixin";
import Loader from "@/components/base/Loader";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import {
  permissionsToStringMap as permissions,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
import { mapGetters } from "vuex";

const tabToPermission = {
  feed: {
    name: "feed",
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_STOCK],
      UPDATE: [permissions.MANAGE_STOCK],
      DELETE: [permissions.MANAGE_STOCK]
    }
  },
  medicine: {
    name: "medicine",
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_STOCK],
      UPDATE: [permissions.MANAGE_STOCK],
      DELETE: [permissions.MANAGE_STOCK]
    }
  }
};
export default {
  components: {
    feed,
    medicine,
    Loader
  },
  mixins: [redirectsMixin],
  data() {
    return {
      loading: false,
      activeName: "first"
    };
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((component) => {
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/stock",
          query: {
            tab: getFirstTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType
            )
          }
        });
      }
    });
  },

  computed: {
    ...mapGetters("stock", {
      getAllFeeds: "getAllFeeds"
    }),
    computedComponentName: function() {
      return this.$route.query.tab;
    },
    components() {
      return {
        feed: {
          label: this.$t("Comn_feed"),
          ...tabToPermission.feed
        },
        medicine: {
          label: this.$t("Comn_medicines"),
          ...tabToPermission.medicine
        }
      };
    },
    filterTabComponents: function() {
      return this.$gblUAMFilterItemsUserCanView(this.components);
    }
  },
  methods: {
    handleSettingsTabClick(tab) {
      this.rdm__navigateToPathWithLocationId({
        path: "/user/stock",
        query: { tab: tab.paneName }
      });
    },
    handleChangeTabTo: function(tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        path: "/user/settings",
        query: { tab: tabComponentName }
      });
    }
  }
};
</script>

<style lang="scss">
.resources-view {
  $default-font-size: 13px;
  $font-color: #404243;
  // .el-tabs__header {
  //   margin: 0 0 0 !important;
  // }
  // .action-toolbar {
  //   margin-bottom: 10px;
  // }
  .el-menu--horizontal.type-tab {
    width: 100%;
    margin-bottom: 5px;
    > .el-menu-item {
      line-height: 35px;
      height: 35px;
    }
  }
  .el-card__body {
    padding: 0px;
  }
}
</style>
